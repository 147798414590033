import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class Footer extends Component {
	render() {
		return (
			<React.Fragment>
				<footer className="footer-area">
					<div className="row mb-4 ml-4 mr-4 p-0">
						<div className="col-xl">
							<Link to="/" onClick={() => window.scrollTo(0, 0)}>
								<h6>HOME</h6>
							</Link>
						</div>
						<div className="col-xl">
							<Link to="/sobre">
								<h6>A EMPRESA</h6>
							</Link>
						</div>
						<div className="col-xl">
							<Link to="/produtos">
								<h6>PRODUTOS</h6>
								<div className="d-flex flex-column">
									<Link to="/produtos#peculio">
										<p>PECÚLIOS</p>
									</Link>
									<Link to="/produtos#seguro">
										<p>SEGUROS</p>
									</Link>
									<Link to="/produtos#assistencias">
										<p>ASSISTÊNCIAS</p>
									</Link>
								</div>
							</Link>
						</div>
						<div className="col-xl">
							<Link to="/noticias">
								<h6>NOTÍCIAS</h6>
							</Link>
						</div>
						<div className="col-xl">
							<Link to="/representantes">
								<h6>REPRESENTANTES</h6>
							</Link>
						</div>
						<div className="col-xl text-nowrap">
							<h6>CONDIÇÕES GERAIS</h6>
							<div className="d-flex flex-column">
								<a
									href="https://storage.googleapis.com/aspecirlv/condicoes_gerais/uniao_seguradora/CG_VG_UNIAO_102013.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<p>SEGURO DE VIDA EM GRUPO</p>
								</a>
								<a
									href="https://storage.googleapis.com/aspecirlv/condicoes_gerais/uniao_seguradora/CG_APC_UNIAO_072013.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<p>SEGURO DE ACIDENTES PESSOAIS</p>
								</a>
								<a
									href="https://storage.googleapis.com/aspecirlv/condicoes_gerais/uniao_seguradora/CG_PREST_UNIAO_102013.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<p>SEGURO PRESTAMISTA</p>
								</a>
								<a
									href="https://storage.googleapis.com/aspecirlv/condicoes_gerais/uniao_seguradora/CG_AF_UNIAO_072013.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<p>SEGURO DE AUXÍLIO FUNERAL</p>
								</a>
								<a
									href="https://uniaoseguradora.com.br/seguro-viagem"
									target="_blank"
									rel="noopener noreferrer"
								>
									<p>SEGURO VIAGEM</p>
								</a>
							</div>
						</div>
						<div className="col-xl text-nowrap">
							<a
								href="https://cliente.grupoaspecir.com.br/login"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h6>PORTAL DO CLIENTE</h6>
							</a>
						</div>
					</div>
					<div className="container">
						{/* <div className="row mb-20">
							<div className="col-lg-6 col-md-6">
								<p>
									Copyright @ {new Date().getFullYear()} {this.props.companyName}. Todos os direitos
									reservados.
								</p>
							</div>
						</div> */}
						<div className="row">
							<div className="col-lg-4">
								<h6>OUVIDORIA</h6>
								<p className="mb-0">
									<b>Telefone:</b> 0800 703 1989
								</p>
								<p className="mb-0">
									<b>E-mail:</b> ouvidoria@sinapp.org.br
								</p>
								<p className="mb-0">
									<b>Site:</b> 
									<a 
										href="https://sisgo.sinapp.net.br/solicitacao#step-1"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://sisgo.sinapp.net.br/solicitacao#step-1
									</a>
								</p>
							</div>
							<div className="col-lg-4">
								<h6>OUVIDORIA DEFICIENTE VISUAL</h6>
								<p className="mb-0">
									<b>Telefone:</b> 0800 703 1989
								</p>
								<p className="mb-0">
									<b>E-mail:</b> ouvidoria.visual@sinapp.org.br
								</p>
							</div>
							<div className="col-lg-4">
								<h6>OUVIDORIA DEFICIENTE AUDITIVO OU DE FALA</h6>
								<p className="mb-0">
									<b>Telefone:</b> 0800 201 1838 (somente através de telefone fixo TDD)
								</p>
								<p className="mb-10">
									<b>E-mail:</b> ouvidoria.auditivo.fala@sinapp.org.br
								</p>
							</div>
						</div>
					</div>
				</footer>
			</React.Fragment>
		);
	}
}
Footer.PropsTypes = {
	companyName: PropTypes.string,
};
Footer.defaultProps = {
	companyName: "",
};
export default Footer;
